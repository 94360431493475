.outerContainer {
  margin-top: 230px;
  padding: 0 4%;
}

.container {
  max-width: 1660px;
  margin: 0 auto;
  padding: 29px 62px 0;
  border-radius: 20px;
  background-color: var(--primary);
  color: #ffffff;
  overflow-y: hidden;
}

.container,
.contentContainer {
  display: flex;
  justify-content: center;
}

.contentContainer {
  text-align: left;
  flex-direction: column;
}

.contentContainer > h2 {
  font-family: "Gilroy", sans-serif;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.4px;
}

.contentContainer > p {
  max-width: 400px;
  font-size: 18px;
  line-height: 145%;
}

.imageContainer {
  position: relative;
  top: 7px;
}

.imageContainer > img {
  width: 100%;
}

.buttonContainer,
.contentContainer > p {
  margin-top: 25px;
}

.buttonContainer button {
  font-family: "Gilroy", sans-serif;
  border-radius: 100px;
  padding: 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
}

.mailButton {
  min-width: 246px;
  background: var(--secondary3);
  border: 1px solid var(--secondary3);
}

.kitButton {
  min-width: 130px;
  margin-left: 15px;
  background: none;
  border: 1px solid #ffffff;
  text-transform: uppercase;
}

@media (max-width: 1000px) {
  .outerContainer {
    margin-top: 45px;
  }

  .contentContainer {
    text-align: center;
  }

  .container {
    padding: 29px 20px 0;
    flex-direction: column;
    align-items: center;
  }

  .contentContainer > p {
    margin: 18px auto 0;
  }

  .buttonContainer {
    padding: 27px 0 20px;
  }

  .kitButton {
    margin-top: 15px;
  }

  .buttonContainer {
    margin-top: 0;
  }
}
