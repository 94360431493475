.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 5%;
}

.container div {
  padding-bottom: 26px;
}

.container h1,
.container h2,
.container h3 {
  font-family: "Gilroy", sans-serif;
  line-height: 110%;
  letter-spacing: -0.4px;
  color: var(--neutral);
}

.container h1 {
  padding: 40px 0;
  font-size: 32px;
}

.container h2 {
  padding-bottom: 18px;
  font-size: 22px;
}

.container p,
.container li,
.container a {
  font-size: 18px;
  line-height: 145%;
  color: var(--neutral);
}

.container a {
  color: var(--primary2);
}

@media (max-width: 1000px) {
  .container p {
    font-size: 16px;
  }
}
