.container {
  position: relative;
}

.imageContainer {
  height: 550px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.header {
  max-width: 600px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 110px;
  font-family: "Gilroy", sans-serif;
  text-align: center;
  color: #ffffff;
}

.header > h1 {
  font-size: 90px;
  line-height: 94px;
}

@media (max-width: 800px) {
  .leftBranch,
  .header {
    display: none;
  }

  .imageContainer {
    height: 330px;
  }
}

@media (min-width: 801px) and (max-width: 900px) {
  .imageContainer {
    height: 440px;
  }
}
