.outerContainer {
  background-color: #fff;
}

.descriptionContainer {
  max-width: 1600px;
  margin: 0px auto;
  padding: 45px 9.5% 47px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.descriptionElement {
  flex: 1;
  min-width: 220px;
  margin-left: 4%;
  margin-right: 12%;
  color: var(--neutral);
}

.descriptionElement h2 {
  font-family: "Gilroy", sans-serif;
  font-size: 42px;
  line-height: 46px;
  letter-spacing: -0.4px;
}

.descriptionElement p {
  margin-top: 37px;
  font-size: 18px;
  line-height: 26px;
}

.browserWindow {
  width: 56%;
  max-width: 740px;
  height: 56%;
  margin: auto;
}

@media (max-width: 1000px) {
  .descriptionContainer {
    padding: 85px 22px 47px;
  }

  .descriptionElement {
    margin-left: 0;
    margin-bottom: 37px;
  }

  .browserWindow {
    width: 99%;
    height: 99%;
  }
}
