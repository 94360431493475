.container {
  display: none;
}

@media (min-width: 1001px) {
  .container {
    margin-right: 81px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
  }

  .dropDownContainer {
    width: max-content;
    background: #fff;
    display: flex;
    text-align: center;
    position: relative;
    border-radius: 108px;
  }

  .dropDownContainer select {
    width: auto;
    margin: 0;
    padding: 8px 50px 8px 16px;
    font-family: "Gilroy", sans-serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: -0.4px;
    color: var(--neutral);
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: 0;
    background-image: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }

  .dropDownContainer img {
    position: absolute;
    -ms-flex-item-align: center;
    align-self: center;
    right: 20px;
    pointer-events: none;
  }

  .fundsContainer {
    margin-top: 4px;
  }

  .fundsContainer > .fundsElement:nth-child(1) p {
    margin-top: 11px;
  }
  .fundsContainer > .fundsElement:nth-child(2) p {
    margin-top: 7px;
  }

  .fundsElement {
    margin-top: 58px;
    text-align: left;
  }

  .fundsElement,
  .tarrifElement {
    color: #fff;
  }

  .fundsElement h2 {
    font-size: 42px;
    line-height: 110%;
    letter-spacing: -0.4px;
  }

  .fundsElement p {
    font-size: 18px;
    line-height: 145%;
  }

  .tarrifContainer {
    width: 323px;
    margin: 31px auto 0;
  }

  .tarrifElement {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }

  .tarrifElement > p {
    display: inline-flex;
    align-items: center;
  }

  .tarrifElement > p,
  .tarrifElement > span,
  .subTarrifElement p {
    font-weight: bold;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.4px;
  }

  .subTarrifElement {
    margin-top: 20px;
  }

  .subTarrifElement p {
    color: #fff;
    font-weight: normal !important;
  }

  .colorPop {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    border-radius: 100%;
    border: 3px solid #ffffff;
    box-sizing: border-box;
  }
}
