.mainContainer {
  min-height: 100vh;
  background-image: url("https://images.unsplash.com/photo-1488330890490-c291ecf62571?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

button {
  font-family: inherit;
  background-color: #ffffff15;
  color: #fff;
  border: 1px solid #ffffff43;
  border-radius: 20rem;
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  margin-top: 1.75rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid #ffffff;
  }

  &:active {
    transform: scale(0.95);
  }
}

.mainContainer h1,
p {
  margin: 0;
}

.mainContainer h1 {
  font-size: 2.5rem;
  font-weight: 600;
}

.mainContainer p {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1rem;
}

.mainContainer p.explanation {
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: 1.8rem;
  margin-bottom: 1rem;
}

.mainContainerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  backdrop-filter: blur(0px);
  padding: 2rem 3rem;
  padding-top: 0;
  box-sizing: border-box;
  background-color: #00000020;
}

.mainContainerWrapperInner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.keepItImageFF {
  aspect-ratio: 16 / 9;
  max-width: 25rem;
  width: 100%;
  object-fit: cover;
  border-radius: 0.75rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
  user-select: none;
}

.keepItImage,
.ytVideo {
  margin-top: 3rem;
  aspect-ratio: 16 / 9;
  max-width: 35rem;
  width: 100%;
  object-fit: cover;
  border-radius: 0.75rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
  user-select: none;
}

.safariKeepIt {
  aspect-ratio: 16 / 12;
}

@media screen and (max-width: 768px) {
  .mainContainer h1 {
    font-size: 1.75rem;
    line-height: 1.2;
  }

  .mainContainer p {
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  .mainContainer button {
    font-size: 1rem;
    padding: 0.35rem 1.25rem;
    margin-top: 1.25rem;
  }

  .mainContainerWrapper {
    padding: 2rem;
  }

  .ytVideo,
  .keepItImage,
  .keepItImageFF {
    max-width: 30rem;
  }
}

@media screen and (min-width: 1400px) {
  .ytVideo,
  .keepItImage,
  .keepItImageFF {
    max-width: 35rem;
  }

  .mainContainer h1 {
    font-size: 3rem;
  }

  .mainContainer p {
    font-size: 1.75rem;
  }

  .mainContainerWrapper {
    padding: 5rem 5rem;
    padding-top: 0;
  }

  .mainContainerWrapperInner {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 2400px) {
  .ytVideo,
  .keepItImage,
  .keepItImageFF {
    max-width: 40rem;
  }

  .mainContainer h1 {
    font-size: 3.5rem;
  }

  .mainContainer p {
    font-size: 2rem;
  }

  .mainContainerWrapper {
    padding: 5rem 10rem;
    padding-top: 0;
  }

  .mainContainerWrapperInner {
    margin-top: 4rem;
  }
}
