body {
  overflow-x: hidden;
}

.backgroundGradient {
  background: var(--gradient);
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  min-height: 100vh;
  overflow-y: auto;
}

.backgroundTexture {
  background: url("../../assets/images/texture-6.png") no-repeat;
}

.header {
  max-width: 670px;
  margin: 0 auto 50px;
  padding-top: 50px;
  text-align: center;
}

.header h1 {
  /* font-family: "Roboto", "Helvetica Neue", Arial, sans-serif; */
  font-style: normal;
  font-weight: bold;
  line-height: 125%;
  color: #ffffff;
}

.bodyContainer {
  width: 60%;
  min-width: 700px;
  max-width: 810px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mainContainer {
  width: 800px;
  min-height: 550px;
  margin: 0 auto 50px;
  padding: 15px;
  border-radius: 16px;
  background-color: #ffffff;
}

.logoContainer {
  width: 90%;
  max-width: 1028px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.adBlockerLogo {
  width: 102px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5.5px;
}
.adBlockerLogo:hover,
.adBlockerLogo.selected {
  border: 0.6875px solid #95979D;
  cursor: pointer;
}

.adBlockerLogo > p {
  text-align: center;
}
.adBlockerLogo img {
  height: 40px;
  width: 40px;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: bold;
}

.windowVideo {
  margin-top: 30px;
  margin-right: 40px;
}

.descriptionContainer p {
  padding-top: 20px;
}

.descriptionContainer button {
  width: fit-content;
  margin-top: 20px;
  padding: 16px 24px;
  background: #28af89;
  border-radius: 100px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.descriptionContainer button:hover {
  opacity: 0.85;
}
