.container {
  display: block;
  position: relative;
  padding-left: 35px !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #3b4256;
  width: max-content;
  line-height: 140%;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #27af89;
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
    background-color: #ccc;
  } */

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #27af89;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
/* .container .checkmark:after {
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: white;
  } */

@media screen and (max-width: 1113px) {
  .container {
    font-size: 12px;
  }
}
