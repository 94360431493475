.anchorColor {
  color: var(--neutral1);
}

.container {
  width: 248px;
  height: 359px;
  padding-left: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 20px;
  text-align: left;
}

.documentImage,
.speakerImage,
.shieldImage,
.content {
  position: absolute;
}

.documentImage {
  left: 166px;
  bottom: 291px;
}

.speakerImage {
  left: 135px;
  bottom: 276px;
}

.shieldImage {
  left: 165px;
  bottom: 277px;
}

.content {
  top: 95px;
  padding-right: 32px;
}

.content > h3 {
  max-width: 160px;
  font-family: "Gilroy", sans-serif;
  font-size: 28px;
  line-height: 31px;
  letter-spacing: -0.4px;
  color: var(--secondary3);
}

.content > p {
  margin-top: 30px;
  font-size: 18px;
  line-height: 26px;
}

@media (max-width: 1000px) {
  .container {
    width: 281px;
  }

  .documentImage {
    left: 187px;
    bottom: 283px;
  }

  .speakerImage {
    left: 183px;
    bottom: 266px;
  }

  .shieldImage {
    left: 173px;
    bottom: 278px;
  }
}
