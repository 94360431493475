.outerContainer {
  display: none;
}

@media (max-width: 1000px) {
  :root {
    --pi: 3.14159265359;
    --circleDiameter: 324px;
    --radius: 96;
    --rainforestConservationPercentage: 59;
    --greenInvestmentPercentage: 21;
    --microloansIssuedPercentage: 20;
    --reservesPercentage: 0;
    --personnelPercentage: 0;
    --freelancerPercentage: 0;
    --officePercentage: 0;
    --serverAndSoftwarePercentage: 0;
    --miscellaneousPercentage: 0;
    --travelPercentage: 0;
    --taxesPercentage: 0;
  }

  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container h1 {
    position: absolute;
    font-size: 44.6715px;
    line-height: 110%;
    letter-spacing: -0.372263px;
  }

  .container h1,
  .descriptionContainer {
    font-family: "Gilroy", sans-serif;
    color: #ffffff;
  }

  .container svg {
    width: var(--circleDiameter);
    height: var(--circleDiameter);
  }

  @keyframes rainforestConservationElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) *
            var(--rainforestConservationPercentage) / 100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--rainforestConservationPercentage) / 100)
        );
    }
  }

  @keyframes greenInvestmentElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--greenInvestmentPercentage) / 100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--greenInvestmentPercentage) / 100)
        );
    }
  }

  @keyframes microloansIssuedElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--microloansIssuedPercentage) /
            100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--microloansIssuedPercentage) / 100)
        );
    }
  }

  @keyframes reservesElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--reservesPercentage) / 100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--reservesPercentage) / 100)
        );
    }
  }

  @keyframes personnelElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--personnelPercentage) / 100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--personnelPercentage) / 100)
        );
    }
  }

  @keyframes freelancerElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--freelancerPercentage) / 100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--freelancerPercentage) / 100)
        );
    }
  }

  @keyframes officeElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--officePercentage) / 100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--officePercentage) / 100)
        );
    }
  }

  @keyframes serverAndSoftwareElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--serverAndSoftwarePercentage) /
            100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--serverAndSoftwarePercentage) / 100)
        );
    }
  }

  @keyframes miscellaneousElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--miscellaneousPercentage) / 100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--miscellaneousPercentage) / 100)
        );
    }
  }

  @keyframes travelElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--travelPercentage) / 100
        )
        calc(
          2 * var(--pi) * var(--radius) *
            calc(1 - var(--travelPercentage) / 100)
        );
    }
  }

  @keyframes taxesElement {
    to {
      stroke-dasharray: calc(
          2 * var(--pi) * var(--radius) * var(--taxesPercentage) / 100
        )
        calc(
          2 * var(--pi) * var(--radius) * calc(1 - var(--taxesPercentage) / 100)
        );
    }
  }

  .donutElement,
  .secondDonutElement {
    transform-origin: center center;
    transition: 0.5s ease-in-out;
    stroke-dashoffset: 0;
    cursor: pointer;
  }

  .donutElement:nth-child(1) {
    transform: rotateZ(-90deg);
    animation: rainforestConservationElement 1s ease-in-out forwards;
  }
  .donutElement:nth-child(2) {
    transform: rotateZ(
      calc(
        -90deg + calc(var(--rainforestConservationPercentage) * 360deg / 100)
      )
    );
    animation: greenInvestmentElement 1s ease-in-out forwards;
  }
  .donutElement:nth-child(3) {
    transform: rotateZ(
      calc(
        -90deg + calc(var(--rainforestConservationPercentage) * 360deg / 100) + calc(var(
                --greenInvestmentPercentage
              ) * 360deg / 100)
      )
    );
    animation: microloansIssuedElement 1s ease-in-out forwards;
  }

  .secondDonutElement:nth-child(1) {
    transform: rotateZ(-90deg);
    animation: reservesElement 1s ease-in-out forwards;
  }
  .secondDonutElement:nth-child(2) {
    transform: rotateZ(
      calc(-90deg + calc(var(--reservesPercentage) * 360deg / 100))
    );
    animation: personnelElement 1s ease-in-out forwards;
  }
  .secondDonutElement:nth-child(3) {
    transform: rotateZ(
      calc(
        -90deg + calc(var(--reservesPercentage) * 360deg / 100) + calc(var(
                --personnelPercentage
              ) * 360deg / 100)
      )
    );
    animation: freelancerElement 1s ease-in-out forwards;
  }
  .secondDonutElement:nth-child(4) {
    transform: rotateZ(
      calc(
        -90deg + calc(var(--reservesPercentage) * 360deg / 100) + calc(
            var(--personnelPercentage) * 360deg / 100
          ) + calc(var(--freelancerPercentage) * 360deg / 100)
      )
    );
    animation: officeElement 1s ease-in-out forwards;
  }
  .secondDonutElement:nth-child(5) {
    transform: rotateZ(
      calc(
        -90deg + calc(var(--reservesPercentage) * 360deg / 100) + calc(
            var(--personnelPercentage) * 360deg / 100
          ) + calc(var(--freelancerPercentage) * 360deg / 100) + calc(var(
                --officePercentage
              ) * 360deg / 100)
      )
    );
    animation: serverAndSoftwareElement 1s ease-in-out forwards;
  }
  .secondDonutElement:nth-child(6) {
    transform: rotateZ(
      calc(
        -90deg + calc(var(--reservesPercentage) * 360deg / 100) + calc(
            var(--personnelPercentage) * 360deg / 100
          ) + calc(var(--freelancerPercentage) * 360deg / 100) + calc(
            var(--officePercentage) * 360deg / 100
          ) + calc(var(--serverAndSoftwarePercentage) * 360deg / 100)
      )
    );
    animation: miscellaneousElement 1s ease-in-out forwards;
  }
  .secondDonutElement:nth-child(7) {
    transform: rotateZ(
      calc(
        -90deg + calc(var(--reservesPercentage) * 360deg / 100) + calc(
            var(--personnelPercentage) * 360deg / 100
          ) + calc(var(--freelancerPercentage) * 360deg / 100) + calc(
            var(--officePercentage) * 360deg / 100
          ) + calc(var(--serverAndSoftwarePercentage) * 360deg / 100) + calc(var(
                --miscellaneousPercentage
              ) * 360deg / 100)
      )
    );
    animation: travelElement 1s ease-in-out forwards;
  }
  .secondDonutElement:nth-child(8) {
    transform: rotateZ(
      calc(
        -90deg + calc(var(--reservesPercentage) * 360deg / 100) + calc(
            var(--personnelPercentage) * 360deg / 100
          ) + calc(var(--freelancerPercentage) * 360deg / 100) + calc(
            var(--officePercentage) * 360deg / 100
          ) + calc(var(--serverAndSoftwarePercentage) * 360deg / 100) + calc(
            var(--miscellaneousPercentage) * 360deg / 100
          ) + calc(var(--travelPercentage) * 360deg / 100)
      )
    );
    animation: taxesElement 1s ease-in-out forwards;
  }

  .descriptionContainer {
    min-height: 264px;
    max-width: 328px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    letter-spacing: -0.4px;
  }

  .descriptionHeader {
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }

  .descriptionHeader h3,
  .descriptionHeader span {
    font-size: 28px;
    line-height: 110%;
  }

  .descriptionContainer p {
    padding-top: 9px;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    z-index: 1;
  }

  .outerContainer {
    padding-top: 35px;
    position: relative;
    display: block;
    position: relative;
    background: linear-gradient(
          180deg,
          rgba(72, 191, 190, 0) 0%,
          #41bab8 0.02%,
          rgba(56, 61, 191, 0) 29.93%,
          rgba(40, 175, 137, 0) 174.16%
        )
        top,
      linear-gradient(0, #f2ffa5 0%, rgba(255, 255, 255, 0) 20%) bottom,
      url("../../assets/images/forest.png") top center;
    background-size: cover;
  }

  .animationContainer {
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .animationContainer video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
