header {
  display: flex;
  justify-content: space-between;
}

.anchorLogo {
  margin-top: 13px;
}

.brandLogo {
  width: 245px;
  height: 62px;
}

.translationDropdown {
  width: 50px;
  height: 18px;
  margin-top: 28px;
  padding: 7px 0 7px 7px;
}

.translationDropdown > select {
  width: 32px;
  padding: 6px;
  padding-left: 50px;
  position: relative;
  z-index: 2;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .anchorLogo {
    margin-top: 18px;
  }

  .brandLogo {
    width: 157px;
    height: 39px;
  }

  .translationDropdown {
    margin-top: 21px;
  }
}
