.outerContainer {
  background: linear-gradient(
      0deg,
      #ffffff 14.23%,
      rgba(255, 255, 255, 0) 46.27%,
      rgba(255, 255, 255, 0.10084) 55.04%,
      #ffffff 98.48%
    ),
    url("../../assets/images/cloudy-sky.png");
  margin-bottom: -22%;
}

.descriptionContainer {
  max-width: 1600px;
  margin: 0px auto;
  padding-top: 84px;
  color: var(--neutral);
}

.descriptionElement,
.descriptionContainer {
  text-align: center;
}

.descriptionElement > h2 {
  font-family: "Gilroy", sans-serif;
  font-size: 42px;
  line-height: 46px;
  letter-spacing: -0.4px;
}

.descriptionElement > p,
.subDescriptionElement > p {
  max-width: 573px;
  font-size: 18px;
  line-height: 26px;
}

.descriptionElement > p {
  margin: 23px auto 0;
}

.browserWindow {
  width: 72%;
  max-width: 1066px;
  height: 72%;
  margin: 55px auto 0 3.7%;
}

.subDescriptionElement > p {
  margin: 8px auto 0;
  padding: 0 3.6%;
}

@media (max-width: 700px) {
  .outerContainer {
    margin-bottom: -13rem !important;
  }
}

@media (max-width: 1000px) {
  .outerContainer {
    margin-bottom: -16rem;
  }

  .descriptionContainer {
    padding-top: 74px;
  }

  .descriptionElement > h2 {
    max-width: 209px;
    margin: auto;
  }

  .descriptionElement > p {
    padding: 0 4.5%;
  }

  .browserWindow {
    width: 92%;
    height: 92%;
    margin-top: 41px;
  }
}

@media (min-width: 1001px) {
  .outerContainer {
    margin-bottom: -22%;
  }

  .descriptionImageContainer {
    position: relative;
  }

  .subDescriptionElement {
    position: absolute;
    left: 21%;
    bottom: 29%;
  }

  .subDescriptionElement > p {
    max-width: 384px;
    margin: 0;
    padding: 0;
    font-family: "Gilroy", sans-serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 31px;
    letter-spacing: -0.4px;
    text-align: left;
    color: #fff;
  }
}

@media (min-width: 1441px) {
  .outerContainer {
    background-position-x: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (min-width: 1501px) {
  .outerContainer {
    margin-bottom: -20rem;
  }
}
