.backgroundGradient {
  background: var(--gradient);
  pointer-events: auto !important;
}

.hero {
  background: url("../../assets/images/texture-2.png") no-repeat center,
    linear-gradient(
      0,
      rgba(72, 191, 190, 0) 0%,
      #41bab8 0.02%,
      rgba(56, 61, 191, 0) 29.93%,
      rgba(40, 175, 137, 0) 174.16%
    );
}

.heroComponent {
  max-width: calc(1600px - 71px - 71px);
  margin: 0 auto;
  padding: 0 71px;
}

/* Same image as top with addition of gradient & transparency & semi-circle */
.cloudyGradient {
  background: url("../../assets/images/sphere-clouds.png") no-repeat center top;
  background-size: cover;
}

/* Duplicate from Home.module.css */
.fade {
  background: linear-gradient(
    0deg,
    #ffffff 55.05%,
    rgba(255, 255, 255, 0) 100%
  );
}

@media (max-width: 1000px) {
  .hero {
    background: url("../../assets/images/texture-2.png") no-repeat top,
      linear-gradient(326.25deg, #45bfc7 26.97%, #7693fa 74.97%) bottom;
  }

  .heroComponent {
    padding: 0 24px;
  }
}

@media (min-width: 1200px) {
  .cloudyGradient {
    -webkit-background-size: unset;
    -moz-background-size: unset;
    -o-background-size: unset;
    background-size: unset;
    padding-top: 4rem;
  }
}

@media (min-width: 1440px) {
  .hero,
  .cloudyGradient {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (min-width: 1700px) {
  .cloudyGradient {
    background-size: contain;
  }
}
