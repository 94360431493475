.footerContainer {
  max-width: 1450px;
  margin: 0 auto;
  padding: 124px 4.34% 40px 4%;
  justify-content: space-between;
  position: relative;
}

.footerContainer,
.browserButtons,
.socialLinks {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.contentful {
  width: 46.1%;
  max-width: 600px;
  padding-right: 20px;
}

.contentful > h2 {
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 42px;
  line-height: 46px;
  letter-spacing: -0.4px;
  color: var(--primary);
}

.contentful > p {
  margin-top: 34px;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral);
}

.browserButtons {
  min-height: 124px;
  margin-top: 10px;
  justify-content: flex-start;
  align-content: space-between;
}

.linksContainer {
  width: 25.4%;
  max-width: 302px;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
}

.linksContainer,
.linksContainer > div {
  display: flex;
  justify-content: space-between;
}

.linksContainer > div {
  min-height: 141px;
  padding-right: 10px;
  flex-direction: row;
  gap: 50px;
  margin-top: 20rem;
}

.linksContainer > div > a {
  text-decoration: none;
  color: var(--primary);
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.logoContainer > select {
  margin-top: 40px;
  padding: 6px;
  padding-left: 40px;
  border: 1px solid #9b9b9b;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

.socialLinks {
  min-width: 175px;
  margin-top: 52px;
  justify-content: space-evenly;
}

.copyright {
  position: absolute;
  left: 50%;
  bottom: 43px;
  font-size: 12px;
  line-height: 17px;
  color: var(--neutral);
}

@media (max-width: 600px) {
  .browserButtons {
    display: none;
  }

  .linksContainer {
    min-width: 90%;
    margin-top: 30px;
    justify-content: space-around;
  }

  .logoContainer {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .logoContainer > a {
    order: 1;
  }

  .logoContainer > a > img {
    height: 65px;
    width: 65px;
  }

  .logoContainer > select {
    min-width: 85%;
    margin-top: 35px;
    margin-bottom: 26px;
    order: 0;
  }

  .logoContainer > div.socialLinks {
    margin-top: 0;
    order: 2;
  }

  .copyright {
    bottom: 20px;
  }
}

@media (max-width: 1000px) {
  .footerContainer {
    padding: 78px 16px 60px 16px;
    justify-content: space-around;
  }

  .contentful {
    width: auto;
    min-width: 75%;
  }

  .copyright {
    left: auto;
  }
}
