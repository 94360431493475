.outerContainer {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.container {
  height: 485px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
}

.textContainer {
  padding: 0 10px;
  position: absolute;
  top: 178px;
  font-family: "Gilroy", sans-serif;
  text-align: center;
  color: #ffffff;
}

.textContainer > h1 {
  font-size: 130px;
  line-height: 159px;
}

.textContainer > h3 {
  max-width: 683px;
  margin: 0 auto;
  font-size: 28px;
  line-height: 110%;
  letter-spacing: -0.4px;
}

@media (max-width: 1000px) {
  .outerContainer {
    background: none;
  }

  .container {
    height: 647px;
    margin: 0 4%;
    background: url("../../assets/images/forest-heart.png") no-repeat center;
    border-radius: 20px;
  }

  .container > img {
    display: none;
  }
}

@media (max-width: 1100px) {
  .container {
    padding: 0 16px;
  }

  .container > img {
    width: 100%;
    height: 647px;
  }

  .textContainer > h1 {
    max-width: 311px;
    font-size: 60px;
    line-height: 100%;
  }

  .textContainer > h3 {
    max-width: 311px;
    font-size: 22px;
    line-height: 110%;
    margin-top: 34px;
  }
}
