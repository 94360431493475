.outerContainer {
  max-width: 1173px;
  height: 831px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  background-size: contain;
}

.cardsContainer {
  padding: 19px 23px 47px;
  position: relative;
  color: var(--neutral);
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  white-space: nowrap;
}

.outerContainer button {
  min-width: 246px;
  height: 66px;
  background: var(--secondary3);
  box-shadow: 8px 8px 28px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  padding: 24px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}

.outerContainer button,
.outerContainer a,
.cardsContainer {
  z-index: 1;
}

.browserButton {
  min-width: 299px !important;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}

.browserButton span {
  height: 25px;
  width: 73px;
  font-size: 12px;
  line-height: 13px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outerContainer video {
  width: 100%;
  height: 831px;
  position: absolute;
  top: 0;
  object-fit: none;
}

@media (max-width: 700px) {
  .outerContainer {
    background-size: cover;
  }
}

@media (max-width: 1000px) {
  .outerContainer {
    height: 735px;
  }

  .cardsContainer {
    width: auto;
    display: block;
    text-align: center;
  }

  .outerContainer video {
    height: 735px;
  }
}
