.statisticsChartContainer {
  display: none;
}

@media (min-width: 1201px) {
  .statisticsChartContainer {
    padding-top: 160px !important;
  }
}

@media (min-width: 1001px) {
  .statisticsChartContainer {
    padding: 132px 0 274px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
          180deg,
          rgba(72, 191, 190, 0) 0%,
          #41bab8 0.02%,
          rgba(56, 61, 191, 0) 29.93%,
          rgba(40, 175, 137, 0) 174.16%
        )
        top,
      linear-gradient(0, #f2ffa5 0%, rgba(255, 255, 255, 0) 20%) bottom,
      url("../../assets/images/forest.png") center center;
    background-size: cover;
  }

  .animationContainer {
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  
  .animationContainer video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
