.introductionContainer {
  padding-bottom: 60px;
  position: relative;
  z-index: 1;
}

.introductionMain {
  position: relative;
  padding-top: 204px;
  z-index: 1;
}

.buttonContainer {
  position: relative;
}

.browserButton {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}

.headingFont,
.buttonContainer button,
.buttonContainer > span {
  font-family: "Gilroy", sans-serif;
  color: #fff;
}

.buttonContainer button {
  min-width: 216px;
  height: 62px;
  background: var(--secondary3);
  border-radius: 100px;
  padding: 24px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  border: none;
  text-transform: uppercase;
  margin-top: 19px;
  margin-left: 8px;
  cursor: pointer;
}

.buttonContainer > img {
  position: absolute;
}

.buttonContainer > span {
  position: absolute;
  margin-top: 19px;
  margin-left: 14px;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}

.introductionText {
  font-size: 48px;
  line-height: 55px;
}

.introductionHeading {
  font-size: 140px;
  line-height: 172px;
  position: relative;
  bottom: 28px;
}

.introductionStatistics {
  margin-top: 38px;
  margin-left: 5px;
  font-weight: 600;
  line-height: 23px;
}

.ratings {
  margin-bottom: 6px;
}

.ratings > img {
  margin: 0 2.77px;
}
.ratings > img:nth-child(1) {
  margin-left: 0;
}
.ratings > img:nth-child(5) {
  margin-right: 8px;
}

.availableBrowsers,
.ratings {
  display: flex;
  align-items: center;
}

.availableBrowsers {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.availableBrowsers > span {
  margin-right: 4px;
}

.availableBrowsers img {
  margin: 0 4px;
}

.imageContainer {
  position: absolute;
  top: 0;
  right: -43px;
}

.animationContainer {
  position: absolute;
  top: -40px;
  right: 15px;
}

.animationContainer video,
.animationContainer img {
  height: 745px;
}

@media (max-width: 650px) {
  .imageContainer {
    left: 210px;
  }
}

@media (max-width: 1000px) {
  .introductionMain {
    padding-top: 68px;
  }

  .introductionText {
    font-size: 28px;
    line-height: 32px;
  }

  .introductionHeading {
    width: 286px;
    font-size: 90px;
    line-height: 73px;
    position: initial;
  }

  .buttonContainer button {
    min-width: 246px;
    margin-top: 105px;
    margin-left: 0;
  }

  /* Make responsive and remove diplay: none */
  .buttonContainer > img,
  .buttonContainer > span {
    display: none;
  }

  .introductionStatistics {
    margin-top: 65px;
    margin-left: 9px;
  }

  .imageContainer {
    top: 86px;
  }
  .imageContainer > img {
    height: 415px;
  }

  .animationContainer {
    top: -16px;
    left: -365px;
  }

  .animationContainer video,
  .animationContainer img {
    height: 460px;
  }
}
