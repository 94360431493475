.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 5%;
}

.container div {
  padding-bottom: 22px;
}

.container h1 {
  padding: 80px 0 133px;
  font-family: "Gilroy", sans-serif;
  font-size: 90px;
  line-height: 110%;
  letter-spacing: -0.4px;
  color: #ffffff;
  text-align: center;
}

.container p {
  font-size: 18px;
  line-height: 145%;
  color: var(--neutral);
}

@media (max-width: 1000px) {
  .container h1 {
    padding: 40px 0 60px;
    font-size: 40px;
  }

  .container p {
    font-size: 16px;
  }
}
