.container {
  height: 88px;
  padding: 0 38px 0 70px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #fff;
  -webkit-transition: visibility 0.2s ease, opacity 0.2s ease;
  -moz-transition: visibility 0.2s ease, opacity 0.2s ease;
  transition: visibility 0.2s ease, opacity 0.2s ease;
}

.logoContainer {
  padding: 13px 0;
}

.logoContainer img {
  cursor: pointer;
}

.shortLogo {
  display: none;
}

.linkContainer {
  padding: 19px 0;
}

.textLink,
.linkContainer button {
  text-transform: uppercase;
}

.textLink {
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  color: var(--neutral);
}

.browserButton,
.downloadButton {
  min-width: 194px;
  height: 50px;
  margin-left: 70px;
  padding: 16px 24px;
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 1px;
  color: #ffffff;
  background: var(--secondary3);
  border-radius: 100px;
  border: none;
  cursor: pointer;
}

.downloadButton {
  display: none;
}

@media (max-width: 1000px) {
  .container {
    height: 64px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
  }

  .linkContainer {
    display: flex;
    align-items: center;
  }

  .wideLogo,
  .browserButton,
  .buttonLink,
  .textLink {
    display: none;
  }

  .shortLogo,
  .downloadButton,
  .shortWindowDownloadLink {
    display: block;
  }

  .downloadButton {
    min-width: 133px;
    height: 31px;
    margin-left: 13px;
    padding: 8px 16px;
    font-size: 14px;
  }
}
