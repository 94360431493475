.carouselContainer {
  height: 429px;
  padding-top: 77px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
}

.carouselCard {
  width: 589px;
  height: 284px;
  padding: 55px 55px 46px;
  opacity: 1;
  border-radius: 20px;
  position: absolute;
  background: #ffffff;
  z-index: -1;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 200ms ease-in-out;
}

.carouselCard.active {
  opacity: 1;
  z-index: 1;
}

.carouselCard.previous,
.carouselCard.next {
  z-index: 0;
  opacity: 0.4;
  top: 0;
}

.carouselCard.previous {
  left: -19%;
}

.carouselCard.next {
  right: -19%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.carouselButton {
  background: 0;
  border: 0;
  cursor: pointer;
}

.leftCarouselButton,
.rightCarouselButton {
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.4);
}

.leftCarouselButton {
  transform: rotate(90deg);
}

.rightCarouselButton {
  transform: rotate(270deg);
}

.feedback {
  height: 90px;
  margin-bottom: 28px;
  padding: 0 9px;
}

.feedback p {
  font-size: 18px;
  line-height: 145%;
  color: var(--neutral);
}

.userDetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.userDetails img {
  width: 77px;
  height: 77px;
  border-radius: 100%;
}

.userSubDetail {
  margin-left: 12px;
}

.userSubDetail h1 {
  font-family: "Gilroy", sans-serif;
  color: #111029;
}

@media (max-width: 768px) {
  .carouselContainer {
    height: 500px !important;
    padding-top: 77px !important;
  }

  .carouselCard {
    width: 313px !important;
    height: 360px !important;
  }

  .feedback {
    height: 170px;
    margin-bottom: 31px;
  }
}

@media (max-width: 1000px) {
  .carouselCard {
    width: 589px;
    height: 284px;
    padding: 40px 19px 0;
  }

  .carouselCard.previous {
    left: -50%;
  }

  .carouselCard.next {
    right: -50%;
  }
}

@media (max-width: 400px) {
  .carouselCard.previous {
    left: -70% !important;
  }

  .carouselCard.next {
    right: -70% !important;
  }
}

@media (min-width: 401px) and (max-width: 500px) {
  .carouselCard.previous {
    left: -60% !important;
  }

  .carouselCard.next {
    right: -60% !important;
  }
}

@media (min-width: 501px) and (max-width: 600px) {
  .carouselCard.previous {
    left: -50% !important;
  }

  .carouselCard.next {
    right: -50% !important;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .carouselCard.previous {
    left: -30% !important;
  }

  .carouselCard.next {
    right: -30% !important;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .carouselCard.previous {
    left: -60% !important;
  }

  .carouselCard.next {
    right: -60% !important;
  }
}
