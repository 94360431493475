.componentContainer {
  max-width: 1600px;
  margin: 0px auto;
  padding-top: 84px;
  color: var(--neutral);
}

.componentHeading,
.componentContainer {
  text-align: center;
}

.componentHeading > h2 {
  font-family: "Gilroy", sans-serif;
  font-size: 42px;
  line-height: 46px;
  letter-spacing: -0.4px;
}

.componentHeading > p {
  max-width: 573px;
  font-size: 18px;
  line-height: 26px;
  margin: 20px auto 0;
}

.buttonContainer {
  max-width: 1270px;
  margin: auto;
  padding: 0 1%;
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-between;
}

.buttonContainer,
.buttonElement {
  display: flex;
  align-items: center;
}

.buttonElement {
  width: 23.4%;
  max-width: 295px;
  height: 109px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin-top: 35px;
  justify-content: center;
  background: rgba(255, 255, 255, 0.33);
  box-sizing: border-box;
}
.buttonElement:hover {
  cursor: pointer;
}

.subContainer > h1 {
  margin: 55px auto 0;
  font-size: 130px;
  line-height: 159px;
  text-align: center;
}

.subContainer > h1,
.subContainer > h2 {
  font-family: "Gilroy", sans-serif;
  color: #ffffff;
}

.subContainer > h2 {
  width: fit-content;
  margin: auto;
  font-size: 90px;
  line-height: 110px;
  text-align: center;
  position: relative;
  left: 250px;
  bottom: 40px;
}

@media (max-width: 1000px) {
  .componentContainer {
    padding-top: 74px;
  }

  .componentHeading > h2 {
    max-width: 300px;
    margin: auto;
  }

  .componentHeading > p {
    margin-top: 14px;
    padding: 0 4.5%;
  }

  .buttonContainer {
    min-width: unset;
    max-width: 340px;
  }

  .buttonContainer > .buttonElement:nth-child(1) > img {
    width: 106px;
  }

  .buttonContainer > .buttonElement:nth-child(1),
  .buttonContainer > .buttonElement:nth-child(2) {
    margin-top: 41px;
  }

  .buttonContainer > .buttonElement:nth-child(2) > img,
  .buttonContainer > .buttonElement:nth-child(3) > img {
    width: 82px;
  }

  .buttonContainer > .buttonElement:nth-child(4) > img {
    width: 88px;
  }

  .buttonContainer > .buttonElement:nth-child(3),
  .buttonContainer > .buttonElement:nth-child(4) {
    margin-top: 12.7px;
  }

  .buttonElement {
    width: 48%;
    max-width: 162px;
    height: 62px;
  }

  .ecosiaColoured {
    height: 48px !important;
  }

  .subContainer > h1 {
    margin-top: 25px;
  }

  .subContainer > h1,
  .subContainer > h2 {
    max-width: 400px;
    font-size: 44px;
    line-height: 54px;
  }

  .subContainer > h2 {
    width: 64%;
    left: 50px;
    bottom: 0;
  }
}
