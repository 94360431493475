.flexContainer {
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 165px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profileHeader {
  max-width: 445px;
  position: relative;
}

.detailsContainer {
  position: absolute;
  bottom: 33px;
  left: -33px;
}

.detailsContainer > h2 {
  max-width: min-content;
  font-family: "Gilroy", sans-serif;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.4px;
  color: #ffffff;
}

.positionContainer {
  width: 150px;
  height: 42px;
  margin-top: 14px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 48px;
  justify-content: center;
}

.positionContainer > p {
  font-weight: 600;
  text-transform: uppercase;
}

.aboutSection,
.positionContainer > p {
  font-size: 18px;
  line-height: 145%;
  color: var(--neutral);
}

.aboutSection {
  max-width: 381px;
  padding-top: 45px;
}

@media (max-width: 1000px) {
  .flexContainer {
    padding: 0 24px 90px;
    flex-direction: column;
    align-content: center;
  }

  .profileHeader {
    max-width: 326px;
    text-align: center;
  }

  .profileHeader > img {
    width: 272px;
  }

  .detailsContainer {
    position: relative;
    bottom: 55px;
    left: 0;
    text-align: left;
  }

  .aboutSection {
    max-width: 326px;
    padding-top: 28px;
    position: relative;
    bottom: 55px;
  }
}
