.descriptionContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.descriptionContainer,
.textContainer {
  align-content: center;
  flex-wrap: wrap;
}

.descriptionElement {
  min-width: 220px;
  margin-bottom: 6rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: var(--neutral);
}

.descriptionElement > a {
  display: contents;
}

.descriptionElement > h2 {
  max-width: 744px;
  margin: auto;
  font-family: "Gilroy", sans-serif;
  font-size: 42px;
  line-height: 46px;
  letter-spacing: -0.4px;
  text-align: center;
}

.rainforestLogo {
  height: 80px;
  margin: 0 auto 27px;
}

.rainforestLogo,
.birdTreeImage {
  z-index: 1;
}

.birdTreeImage {
  width: 35%;
  max-width: 455px;
  margin-right: auto;
  margin-bottom: 16%;
}

.monkeyTreeImage {
  width: 33%;
  max-width: 464px;
  margin-top: 3%;
  margin-left: auto;
}

.textContainer {
  max-width: 1600px;
  margin: 0px auto;
  align-items: flex-start;
  justify-content: center;
  color: var(--neutral);
  display: inline;
  position: relative;
}

.textContainer > p {
  min-width: 350px;
  max-width: 418px;
  font-size: 18px;
  line-height: 26px;
  flex: 1;
  position: absolute;
  margin-top: 26px;
}

.textContainer > p:nth-child(1) {
  min-width: 418px;
  right: 5rem;
}

.textContainer > p:nth-child(2) {
  left: 1rem;
}

.textContainer > p a > button,
.descriptionElement > a > button {
  width: 185px;
  margin-top: 34px;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  background: var(--secondary3);
  border-radius: 100px;
  padding: 16px 24px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}

.descriptionElement > a > button {
  margin: 38px auto 0;
  display: none;
}

.textContainer > p a,
.descriptionElement > a {
  text-decoration: none;
}

@media (max-width: 400px) {
  .descriptionElement {
    margin-top: 165%;
  }
}

@media (min-width: 401px) and (max-width: 500px) {
  .descriptionElement {
    margin-top: 155%;
  }
}

@media (min-width: 501px) and (max-width: 600px) {
  .descriptionElement {
    margin-top: 145%;
  }
}

@media (max-width: 600px) {
  .descriptionContainer {
    position: relative;
  }

  .descriptionElement {
    margin-left: 0;
    order: 2;
  }

  .birdTreeImage {
    width: 80.8%;
    margin-bottom: 0;
    order: 0;
    position: absolute;
    top: 232px;
    left: 0;
    z-index: 1;
  }

  .monkeyTreeImage {
    width: 94%;
    margin-top: 64px;
    order: 1;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 601px) and (max-width: 700px) {
  .birdTreeImage {
    top: 30%;
  }

  .descriptionElement {
    margin-top: 150%;
  }
}

@media (min-width: 701px) and (max-width: 800px) {
  .birdTreeImage {
    top: 20%;
  }

  .descriptionElement {
    margin-top: 110%;
  }
}

@media (min-width: 801px) and (max-width: 900px) {
  .birdTreeImage {
    top: 15%;
  }

  .descriptionElement {
    margin-top: 85%;
  }
}

@media (min-width: 901px) and (max-width: 1000px) {
  .birdTreeImage {
    top: 10%;
  }

  .descriptionElement {
    margin-top: 60%;
  }
}

@media (min-width: 601px) and (max-width: 1000px) {
  .descriptionContainer {
    position: relative;
  }

  .descriptionElement {
    margin-left: 0;
    order: 2;
  }

  .birdTreeImage {
    width: 80.8%;
    margin-bottom: 0;
    order: 0;
    position: absolute;
    left: 0;
    z-index: 1;
  }

  .monkeyTreeImage {
    width: 94%;
    margin-top: 64px;
    order: 1;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .descriptionContainer {
    padding-bottom: 30px;
  }
}

@media (max-width: 1000px) {
  .descriptionElement {
    margin-bottom: 0;
  }

  .descriptionElement > h2 {
    width: 80%;
    max-width: 573px;
    padding: 0 4.5%;
    font-size: 28px;
    line-height: 31px;
    text-align: left;
  }

  .textContainer {
    max-width: 573px;
    padding: 0 4.5%;
    margin: 0 auto;
    display: flex;
  }

  .textContainer > p {
    width: 80%;
    margin-top: 26px;
    position: relative;
  }

  .textContainer > p:nth-child(1),
  .textContainer > p:nth-child(2) {
    max-width: 573px;
    min-width: 300px;
    left: 0;
    right: 0;
  }

  .textContainer > p a > button {
    display: none;
  }

  .descriptionElement > a > button {
    margin-bottom: 97px;
    display: block;
  }
}
