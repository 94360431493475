.green {
  color: var(--primary);
  font-size: 40px;
}

.submit {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  height: 50px;
  background: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 100px;
  font-weight: 900;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px
}

.lastStep {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lastStep > img {
  width: 200px;
  height: 50px;
  margin-bottom: 70px;
}

.lastStep h1 {
  font-size: 30px;
  margin: 0;
  color:#3b4256;
}

.lastStep p {
  margin-top: 40px;
}

.bullets {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  margin-bottom: 20px;
}

.bullets:first-of-type{
    margin-top:30px
}

.bullets img {
  width: 34px;
  height: 32px;
}

.bullets p {
  margin: 0;
  margin-left: 10px;
}



.innerContainer {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 589px;
  max-height: 362px;
  backdrop-filter: blur(2px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #0003;
}

.innerContainer h4 {
  font-size: 24px;
  font-weight: 900;
  color: #fff;
  margin: 0;
  margin-bottom: 10px;
}

.innerContainer h5 {
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
}

.innerContainer .innerWhite {
  box-sizing: border-box;
  width: 413px;
  height: 168px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}
.innerWhitePara { 
  font-weight: 900;
  font-size: 18px;
  margin-bottom: 10px;
  color: #3b4256;
}

.innerWhiteSkeleton {
  width: 150.5px;
  height: 0px;
  opacity: 0.1;
  border: 10px solid #000000;
  border-radius: 50px;
  margin-bottom: 25px;
}

.innerInner {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.innerBtn {
  position: relative;
  width: 104px;
  height: 40px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  display: grid;
  place-items: center;
  color: var(--primary);
  font-weight: 700;
  font-size: 14px;
}

.innerSkeletonButton {
  width: 104px;
  height: 40px;
  background: #e4e4e4;
  border-radius: 4px;
}

.cursor {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 10px;
  left: 60px;
}
