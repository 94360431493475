.flexContainer {
  padding-top: 66px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.textContainer {
  flex: 1;
  max-width: 536px;
  color: #fff;
  z-index: 1;
}

.textContainer > h1 {
  font-size: 90px;
}

.textContainer > h1,
.textContainer > h4 {
  font-family: "Gilroy", sans-serif;
  letter-spacing: -0.4px;
  line-height: 110%;
}

.textContainer > h4 {
  font-size: 22px;
}

.textContainer > p {
  font-size: 18px;
  line-height: 145%;
}

.flexContainer > img {
  width: 25.2%;
  max-width: 323px;
  margin: auto;
}

@media (max-width: 1000px) {
  .flexContainer {
    padding: 95px 0 47px;
    flex-direction: column;
  }

  .textContainer > h1 {
    font-size: 60px;
  }

  .textContainer > p {
    font-size: 18px;
    line-height: 145%;
  }

  .flexContainer > img {
    width: 65%;
    margin-top: 40px;
  }
}

@media (min-width: 1001px) {
  .flexContainer {
    padding-bottom: 200px;
  }
}
