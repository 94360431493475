.badge {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
}

@media (max-width: 1200px) {
  .badge {
    width: 110px;
  }
}
