.flexContainer {
  padding-top: 77px;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: #fff;
}

.flexContainer > h1 {
  max-width: 400px;
  font-size: 90px;
  font-family: "Gilroy", sans-serif;
  line-height: 110%;
  letter-spacing: -0.4px;
}

.textContainer {
  width: 41%;
  max-width: 528px;
  margin: auto;
}

.textContainer > p {
  font-size: 18px;
  line-height: 145%;
  padding-top: 30px;
}

@media (max-width: 1000px) {
  .flexContainer {
    padding-top: 94px;
    flex-direction: column;
  }

  .flexContainer > h1 {
    font-size: 60px;
  }

  .textContainer {
    width: auto;
  }

  .textContainer > p {
    font-size: 18px;
    line-height: 145%;
  }
}
