.button {
  width: 190px;
  height: 50px;
  margin-top: 24px;
  margin-right: 10px;
  padding-left: 14px;
  border: 1px solid rgb(0 0 0 / 50%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: black;
  text-decoration: none;
}

.button > div {
  margin-bottom: 5px;
}

.button > img {
  margin-right: 7px;
}

.text {
  font-size: 11px;
  line-height: 16px;
}

.subtext {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}
