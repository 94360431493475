.root {
  box-sizing: border-box;
  background-color: var(--primary);
  height: 100vh;
  padding: 30px;
}

.container {
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 4fr 4fr;
}

.left {
  background-color: #fff;
  padding: 30px 40px;
}

.left img {
  width: 99px;
  height: 25px;
}

.left h1 {
  font-size: 35px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #3b4256;
}
.left h2 {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  color: #3b4256;
}

.right {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.right > img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.container:last-child {
  background-color: var(--primary);
}

.static {
  padding: 0;
}

.static .container:last-child {
  background-color: #fff;
}

.static .container {
  grid-template-columns: 4fr 4fr;
  place-items: center;
  border-radius: 0;
}

.static .right {
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .left {
    padding-top: 50px;
    margin: 0 auto;
    place-self: baseline;
  }
}

@media screen and (max-width: 1113px) {
  .left h1 {
    font-size: 26px;
  }

  .left h2 {
    font-size: 14px;
  }
}

.uninstallPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
