.container {
  position: relative;
}

.imageContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}

.branchImage {
  margin-bottom: -84px;
}

.header {
  max-width: 361px;
  margin: 0 auto;
  top: 14%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header,
.variableContent button {
  font-family: "Gilroy", sans-serif;
  color: #ffffff;
  position: absolute;
}

.header > h1 {
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.4px;
}

.variableContent {
  padding-top: 25px;
  position: relative;
}

.animationContainer {
  position: absolute;
  top: 0px;
}

.animationContainer video {
  width: 100%;
}

.svgYear {
  width: max-content;
}

.svgYear svg {
  height: 200px;
  padding-right: 5px;
  stroke-width: 3px;
}

@keyframes offset {
  to {
    stroke-dashoffset: 0;
  }
}

.svgYear svg g {
  stroke: #ffffff;
  animation: offset 3s 4s linear forwards;
}

.variableContent button {
  min-width: 216px;
  height: 62px;
  padding: 24px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  background: var(--secondary3);
  border-radius: 100px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  bottom: -35px;
  right: -65px;
  z-index: 1;
}

@media (max-width: 600px) {
  .cloudImage {
    position: relative;
    left: -170px;
  }
}

@media (max-width: 1000px) {
  .header {
    max-width: 309px;
    top: 36%;
  }

  .branchImage {
    position: relative;
    right: 120px;
    margin-bottom: 0;
  }

  .variableContent {
    padding-top: 47px;
  }

  .variableContent button {
    position: initial;
    margin-top: 28px;
  }

  .svgYear svg {
    height: 100px;
  }
}
