.card {
  width: 32%;
  max-width: 343px;
  height: 313px;
  background: linear-gradient(
    180deg,
    #eeeeee 0%,
    rgba(255, 255, 255, 0.79) 100%
  );
  border: 1px solid #ffffff;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.card,
.title {
  box-sizing: border-box;
  display: flex;
  text-align: center;
}

.title {
  height: 70px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background: var(--primary2);
  border: 1px solid #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-transform: uppercase;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}

.description {
  height: 243px;
  padding: 0 30px;
  white-space: break-spaces;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: var(--neutral1);
  display: flex;
  align-items: center;
}

@media (max-width: 1000px) {
  .card {
    width: 83%;
    max-width: 303px;
    display: inline-block;
    margin-right: 18px;
  }

  .description {
    padding: 0 10px;
  }
}
