.backgroundGradient {
  background: var(--gradient);
}

.topContainer {
  padding-right: 43px;
  padding-left: 70px;
  overflow: hidden;
}

/* Duplicate from Home.module.css */
.fade {
  background: linear-gradient(
    0deg,
    #ffffff 55.05%,
    rgba(255, 255, 255, 0) 100%
  );
}

@media (max-width: 1000px) {
  .topContainer {
    padding-right: 31.5px;
    padding-left: 24px;
  }
}
