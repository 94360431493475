.treeCounter {
  min-width: 347px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.89);
  color: var(--primary);
  border-radius: 20px;
  font-family: "Gilroy", sans-serif;
  position: absolute;
  top: calc(260px + 204px);
  right: 0;
}

.bigCounter {
  font-size: 40px;
  line-height: 49px;
}

.smallCounter {
  display: none;
  font-size: 26px;
  line-height: 32px;
}

.treeCounter > p {
  color: var(--neutral);
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

.strokeContainer img {
  position: absolute;
  left: -115px;
  top: 41px;
}

.colorPop {
  width: 20px;
  height: 20px;
  background: var(--primary);
  border-radius: 100%;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  position: absolute;
  left: -10px;
  top: 33px;
}

@media (max-width: 400px) {
  .strokeContainer img {
    top: -63px !important;
    left: 206px !important;
  }

  .colorPop {
    top: -10px !important;
    left: 250px !important;
  }
}

@media (max-width: 1000px) {
  .treeCounter {
    min-width: auto;
    max-width: 327px;
    margin-top: 35px;
    position: relative;
    top: 0;
  }

  .bigCounter {
    display: none;
  }

  .smallCounter {
    display: block;
  }

  .strokeContainer img {
    transform: rotate(90deg);
    top: -63px;
    left: 256px;
  }

  .colorPop {
    top: -10px;
    left: 300px;
  }
}
