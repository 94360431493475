.submit {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  height: 50px;
  background: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 100px;
  font-weight: 900;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px;
}

.lastStep {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lastStep > img {
  width: 106px;
  height: 106px;
  margin-bottom: 70px;
}

.lastStep h1 {
  font-weight: 700;
  font-size: 42px;
  margin: 0;
  color: #3b4256;
}

.lastStep h4 {
  font-weight: 400;
  font-size: 18px;
  color: #3b4256;
}
