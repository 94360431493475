.flexContainer {
  max-width: 1440px;
  margin: 0 auto;
  padding: 70px 4% 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.card {
  max-width: calc(335px - 51px);
  margin-top: 24px;
  padding-top: 50px;
  padding-left: 51px;
  color: var(--neutral);
  position: relative;
}

.card .svgNumbers {
  position: absolute;
  top: 0;
  left: 0;
}

.card h3 {
  font-family: "Gilroy", sans-serif;
  font-size: 28px;
  line-height: 110%;
  letter-spacing: -0.4px;
  mix-blend-mode: darken;
}

.flexContainer > .card:nth-child(1) h3 {
  position: relative;
  top: 6px;
}

.card p {
  padding-top: 31px;
  font-size: 18px;
  line-height: 145%;
}

@media (max-width: 1000px) {
  .flexContainer {
    padding: 0 5% 117px;
    flex-direction: column;
    align-items: center;
  }
}
