.selectors {
  position: absolute;
  right: 0;
  height: 100%;
  margin-right: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selector {
  width: 11px;
  height: 11px;
  margin: 7px 0;
  border: 1px solid white;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
}

.selector:hover,
.active {
  background: white;
}

@media (max-width: 1000px) {
  .selectors {
    margin-right: 25px;
  }
}
