.calendly-inline-widget {
  height: calc(100% + 68px) !important;
  min-width: 100% !important;
  width: 100% !important;
  margin-top: -60px !important;
  position: absolute;
  left: 17px;
  top: -8px;
}

.xahN8AEzyAvQtVj17TPv {
  margin: 0 !important;
}

@media screen and (max-width: 1190px) {
  .calendly-inline-widget {
    margin-top: 0px !important;
  }
}
