.backgroundGradient {
  background: var(--gradient);
}

.hero {
  position: relative;
  background: linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0) 30%),
    url("../../assets/images/texture-2.png") no-repeat center;
}

.animationContainer {
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.animationContainer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topContainer,
.heroComponent {
  padding: 0 71px;
  position: relative;
  z-index: 1;
}

.heroComponent {
  max-width: calc(1600px - 71px - 71px);
  margin: 0 auto;
}

.transparency {
  background: linear-gradient(0, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
    linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.cloudyGradientTexture {
  padding: 150px 0 100px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 30%),
    url("../../assets/images/texture-5.png") no-repeat center bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.gradientTexture {
  background: url("../../assets/images/texture-4.png") no-repeat left top,
    url("../../assets/images/texture-3.png") no-repeat right -100%;
}

/* Duplicate from Home.module.css */
.fade {
  background: linear-gradient(
    0deg,
    #ffffff 55.05%,
    rgba(255, 255, 255, 0) 100%
  );
}

@media (max-width: 1000px) {
  .topContainer,
  .heroComponent {
    padding: 0 24px;
  }

  .cloudyGradientTexture {
    background-position-y: top;
  }

  .gradientTexture {
    background: url("../../assets/images/texture-4.png") no-repeat left top,
      url("../../assets/images/texture-3.png") no-repeat right top;
  }
}

@media (min-width: 1000px) {
  .teamsTransparency {
    background: linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }
}

@media (min-width: 1440px) {
  .hero {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
