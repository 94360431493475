.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 5%;
}

.container h1,
.container h2,
.container h3 {
  font-family: "Gilroy", sans-serif;
  line-height: 110%;
  letter-spacing: -0.4px;
  color: var(--neutral);
}

.container h1 {
  padding: 40px 0 20px;
  font-size: 32px;
}

.container h2 {
  padding-bottom: 18px;
  font-size: 22px;
}

.container p,
.container a {
  color: var(--neutral);
}

.container a {
  color: var(--primary2);
}

.horizontalSeparator {
  max-width: 730px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.horizontalSeparator::before,
.horizontalSeparator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #d0d0d0;
}

.horizontalSeparator:not(:empty)::before {
  margin-right: 12px;
}

.horizontalSeparator:not(:empty)::after {
  margin-left: 12px;
}

.highlightBackground {
  padding: 18px 18px 26px;
  background-color: var(--faded-main-green);
  border-radius: 14px;
}

.subHeader {
  margin: 0 0 8px;
  font-size: 18px;
  line-height: 144%;
  font-weight: 600;
}

.subDescription {
  margin: 0 0 8px;
  font-size: 14px;
  line-height: 140%;
}

.flexContainer {
  display: flex;
  align-items: center;
}
.flexContainerSpace {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputBox {
  width: 200px;
  max-height: 45px;
  padding: 12px;
  background: var(--app-input);
  color: var(--popup-text-colour) !important;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  border: none;
  border-radius: 8px;
  outline: none;
}

.link-input {
  height: 40px;
  padding: 10px 12px;
  flex: auto;
  opacity: 0.8;
  border-radius: 4px;
  margin-right: 8px;
}

.button {
  padding: 12px;
  border-radius: 100px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 110%;
  color: #fff;
  cursor: pointer;
}

.imageButton {
  padding: 12px 24px 12px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageButton svg {
  margin-right: 16px;
}

.buttonPrimary {
  background-color: var(--primary-text-colour);
}
.buttonPrimary:hover:not(:disabled),
.buttonText:hover,
.buttonWhite:hover,
.socialIcon:hover path,
.angleIcon:hover path {
  opacity: 0.85;
}
.buttonPrimary:active:not(:disabled),
.buttonText:active,
.buttonWhite:active .socialIcon path:active {
  opacity: 1;
}

.container button:disabled,
.container button[disabled] {
  cursor: default;
  opacity: 0.5;
}

.buttonWhite {
  background: none !important;
}
.buttonBorder {
  box-shadow: 0 0 0 1px var(--primary-text-colour);
}

.buttonText {
  cursor: pointer;
}

.smallButton {
  height: 24px;
  padding: 0 10px;
}

.smallButton.imageButton svg {
  margin-right: 5px;
}

.largeIcon .socialIcon {
  width: 20px;
  height: 20px;
  fill: var(--primary-text-colour);
}

.textArea {
  height: auto;
  min-height: 70px;
}

.text {
  font-size: 14px;
}
.textSmall {
  font-size: 12px;
}
.textSmall,
.text {
  line-height: 140%;
}

.primaryTextColour {
  color: var(--primary-text-colour);
}

.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mb20 {
  margin-bottom: 20px;
}
.mr8 {
  margin-right: 8px;
}

.fw600 {
  font-weight: 600;
}

.mw250 {
  max-width: 250px;
}
.mw700 {
  max-width: 700px;
}
.mw800 {
  max-width: 800px;
}
.mw150 {
  min-width: 150px;
}
.width45 {
  width: 45%;
}
.width60 {
  width: 60%;
}
.width100 {
  width: 100%;
}

.redColour {
  color: red;
}

.container textarea {
  font-family: inherit;
  resize: none;
}
