.container {
  max-width: 1440px;
  max-height: 600px;
  margin: 0 auto;
  position: relative;
}

.container h1,
.container h2 {
  position: absolute;
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  line-height: 86%;
  letter-spacing: -0.4px;
  color: #ffffff;
}

.container h1 {
  top: 21%;
  left: 21%;
  font-size: 90px;
}

.container h2 {
  max-width: 240px;
  right: 16%;
  bottom: 46%;
  font-size: 60px;
}

.container video,
.container img {
  width: 100%;
}

@media (max-width: 1000px) {
  .container {
    overflow: hidden;
  }

  .container h1,
  .container h2 {
    font-size: 42px;
    line-height: 110%;
    text-align: center;
  }

  .container h1 {
    top: 8%;
    right: 0;
    left: 0 !important;
    z-index: 1;
  }

  .container h2 {
    width: 100%;
    max-width: none;
    right: 0;
    bottom: 20%;
    left: 0;
  }

  .container video {
    width: 200%;
    position: relative;
    right: 55%;
  }
}

@media (max-width: 1300px) {
  .container h1 {
    left: 15%;
  }

  .container h2 {
    right: 10%;
  }
}
