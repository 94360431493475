@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url(./fonts/Gilroy/Gilroy-Light.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  src: local("Gilroy"), url(./fonts/Gilroy/Gilroy-ExtraBold.otf) format("opentype");
}

html,
body {
  width: 100vw;
  height: 100vh;
}

body {
  font-family: "Open Sans", sans-serif;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

/* Remove redundant underline styles */
a {
  text-decoration: none;
}

.whiteText {
  color: #fff;
}

:root {
  --neutral: #4e4e4e;
  --primary: #27af89;
  --primary2: #1a8cca;
  --secondary3: #8964a8;
  --faded-main-green: rgba(40, 175, 137, 0.1);
  --app-input: #f8fafc;
  --popup-text-colour: #3b4256;
  --primary-text-colour: #28af89;
  --gradient: linear-gradient(90deg, #a9eced 21.19%, #ebf5ac 96.34%);
}

.longerDelay {
  transition-delay: 0.6s !important;
}

.fadeUpAnimation {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-duration: 0.6s;
  transition-delay: 0.1s;
}

.fadeUpAnimation.is-inview {
  transform: none;
  opacity: 1;
}

.hero {
  position: relative;
  z-index: 3;
}

@keyframes offset {
  to {
    stroke-dashoffset: 0;
  }
}

.numberSvg g {
  fill: none;
  stroke: var(--primary);
  animation: offset 2.5s 2.5s linear forwards;
}

.digit-0 {
  stroke-dasharray: 550;
  stroke-dashoffset: 550;
}
.digit-1 {
  stroke-dasharray: 525;
  stroke-dashoffset: 525;
}
.digit-2 {
  stroke-dasharray: 775;
  stroke-dashoffset: 775;
}
.digit-3 {
  stroke-dasharray: 810;
  stroke-dashoffset: 810;
}
.digit-4 {
  stroke-dasharray: 760;
  stroke-dashoffset: 760;
}
.digit-5 {
  stroke-dasharray: 850;
  stroke-dashoffset: 850;
}
.digit-6 {
  stroke-dasharray: 590;
  stroke-dashoffset: 590;
}
.digit-7,
.digit-8 {
  stroke-dasharray: 630;
  stroke-dashoffset: 630;
}
.digit-9 {
  stroke-dasharray: 600;
  stroke-dashoffset: 600;
}

.numberSvg path {
  stroke-miterlimit: 10;
}

.treesDescription {
  margin-top: 250px;
  padding: 10px 25px;
}
.treesDescription1 {
  padding: 10px 25px;
}

.treesDescription article {
  display: grid;
  row-gap: 30px;
  font-size: 20px;
}
.treesDescription h1 {
  text-transform: capitalize;
}

.treesDescription1 article {
  display: grid;
  row-gap: 30px;
  font-size: 20px;
}
.treesDescription1 h1 {
  text-transform: capitalize;
}
