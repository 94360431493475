.container {
  position: relative;
  background: linear-gradient(
      180deg,
      rgba(72, 191, 190, 0) 0%,
      #f2ffa5 0.01%,
      rgba(56, 61, 191, 0) 30%,
      rgba(40, 175, 137, 0) 174.16%
    )
    top;
}

.imageContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}

.map {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.map h2 {
  max-width: 730px;
  margin: 0 auto;
  padding: 0 4%;
  font-family: "Gilroy", sans-serif;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.4px;
  color: var(--neutral);
}

.map svg {
  max-width: 100%;
  height: auto;
  margin: 40px 0 37px;
}

.activeProject {
  fill: var(--primary);
  opacity: 0.5;
  cursor: pointer;
}
.activeProject:hover {
  opacity: 1;
}

.projectContainer {
  width: 216px;
  height: 220px;
  margin: 0 auto;
  position: absolute;
  bottom: 50px;
  background: #ffffff;
  box-shadow: 8px 8px 44px rgba(14, 56, 95, 0.17);
  border-radius: 8px;
  cursor: pointer;
}

.projectImage {
  width: 88px;
  height: 88px;
  border-radius: 100%;
}

.projectImage,
.description {
  position: relative;
  bottom: 38px;
}

.description {
  padding: 0 20px;
  text-align: left;
}

.projectContainer h3,
.projectContainer p {
  font-size: 18px;
  line-height: 145%;
  color: var(--primary);
}

.projectName {
  color: var(--neutral) !important;
}

@media (max-width: 600px) {
  .rightBranch {
    position: relative;
    right: 65px;
  }

  .map {
    margin-top: -60px;
  }
}

@media (min-width: 801px) and (max-width: 1000px) {
  .map {
    margin-top: -150px;
  }

  .map h2 {
    max-width: 340px;
  }
}

@media (max-width: 1000px) {
  .projectContainer {
    width: auto;
    max-width: 335px;
    height: 140px;
    min-height: auto;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
  }

  .projectImage {
    position: relative;
    right: 11px;
  }

  .projectImage,
  .description {
    bottom: 0;
  }

  .description {
    padding-left: 17px;
  }
}

@media (min-width: 1001px) {
  .map {
    margin-top: -215px;
  }

  .map svg {
    margin: 30px 0 0;
  }
}

@media (max-width: 1440px) {
  .map h2 {
    max-width: 570px;
  }
}
