.backgroundGradient,
.footerGradientTexture {
  position: relative;
}

.backgroundGradient,
.gradient {
  background: var(--gradient);
}

.backgroundTexture {
  padding-bottom: 25px;
  background: url("../../assets/images/texture-1.png") no-repeat;
}

.topContainer {
  padding-right: 43px;
  padding-left: 70px;
  overflow: hidden;
}

.transparency {
  width: 100%;
  height: 564px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.fade {
  background: linear-gradient(
    0deg,
    #ffffff 55.05%,
    rgba(255, 255, 255, 0) 100%
  );
}

.backgroundImage {
  background: url("../../assets/images/power-of-many.png") no-repeat;
  background-position-x: center;
  background-size: cover;
  position: relative;
}

@media (max-width: 1000px) {
  .backgroundImage {
    padding-bottom: 29rem;
  }
}

@media (min-width: 1001px) and (max-width: 1400px) {
  .backgroundImage {
    padding-bottom: 22rem;
  }
}

@media (min-width: 1401px) {
  .backgroundImage {
    padding-bottom: 30rem;
  }
}

@media (min-width: 1900px) {
  .backgroundImage {
    background-size: contain;
  }
}

@media (max-width: 1000px) {
  .topContainer {
    padding-right: 31.5px;
    padding-left: 24px;
  }

  .transparency {
    height: 635px;
  }

  .alignment {
    padding-top: 12rem;
  }
}

@media (min-width: 1000px) {
  .backgroundTexture {
    background-position-x: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .footerGradientTexture {
    background: url("../../assets/images/texture-4.png") no-repeat left top,
      url("../../assets/images/texture-3.png") no-repeat right bottom;
  }
}
