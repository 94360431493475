.recognition {
  width: 76%;
  max-width: 1080px;
  margin: 77px auto 0;
  padding-top: 28px;
  position: relative;
  color: var(--neutral);
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 20px 20px 44px rgba(0, 0, 0, 0.04);
  z-index: 1;
}

.recognition h4 {
  text-align: center;
  margin: 0 auto;
  font-family: "Gilroy", sans-serif;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.carouselContainer {
  padding: 19px 23px 40px;
  display: flex;
  justify-content: space-evenly;
  overflow-x: auto;
  white-space: nowrap;
}

.carouselItem {
  min-width: 121px;
  display: inline-block;
  text-align: center;
}
.carouselItem:nth-child(n + 3) {
  min-width: 149px;
}

.carouselItem a {
  color: var(--neutral);
  text-decoration: none;
}

.brandLogo {
  height: 83.49px;
  display: flex;
  align-items: center;
}

.brandLogo > img {
  margin: auto;
}

.recognitionTitle {
  margin-top: 19.52px;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}

.recognitionDescription {
  width: 104px;
  margin: 14px auto 0;
  font-size: 14px;
  line-height: 20px;
  white-space: break-spaces;
}

@media (max-width: 1000px) {
  .recognition {
    width: auto;
    border-radius: unset;
  }

  .recognition h4 {
    width: 189px;
  }

  .carouselContainer {
    display: block;
    text-align: center;
  }
}
