.uninstall,
.uninstall * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.uninstall {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: grid;
  place-items: center;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  max-height: 818px;
  background-color: var(--primary);
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.left,
.right {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.leftContainer,
.lastStep {
  height: 100%;
  width: 100%;
  padding: 20px 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 13px;
  justify-content: center;
  overflow-y: scroll;
}

.lastStep > * {
  text-align: center;
}

.lastStep h1 {
  color: #3b4256;
  font-weight: 700;
  font-size: 42px;
  line-height: 125%;
  margin-bottom: 20px;
}

.lastStep h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 145%;
  color: #3b4256;
}

.leftContainer h1 {
  font-weight: 700;
  font-size: 42px;
  line-height: 125%;
  width: 100%;
  color: #3b4256;
  text-align: center;
}

.leftContainer h2 {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 145%;
  text-align: center;
  color: #3b4256;
  padding-bottom: 20px;
}

.radioContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.submit {
  padding: 16px 24px;
  margin-top: 20px;
  background-color: var(--primary);
  border: none;
  outline: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 900;
  font-size: 16px;
  line-height: 110%;
  cursor: pointer;
}

.inputField {
  border: none;
  outline: none;
  border-bottom: 1px solid #95979d;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  padding-block: 10.5px;
  color: #95979d;
}

.link {
  margin-top: 20px;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 145%;
  text-align: center;
  color: #28af89;
}

.hidden {
  display: none !important;
}

.monkey {
  width: 100%;
  object-fit: cover;
}

.calendly {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: scroll;
}
