*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible
}

.static {
  position: static
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.bottom-0 {
  bottom: 0px
}

.bottom-\[-40px\] {
  bottom: -40px
}

.bottom-\[100px\] {
  bottom: 100px
}

.bottom-\[120px\] {
  bottom: 120px
}

.bottom-\[30px\] {
  bottom: 30px
}

.bottom-\[50px\] {
  bottom: 50px
}

.left-0 {
  left: 0px
}

.left-\[-150px\] {
  left: -150px
}

.left-\[-200px\] {
  left: -200px
}

.left-\[10px\] {
  left: 10px
}

.left-\[40px\] {
  left: 40px
}

.right-0 {
  right: 0px
}

.right-\[-100px\] {
  right: -100px
}

.right-\[-150px\] {
  right: -150px
}

.right-\[-240px\] {
  right: -240px
}

.right-\[-300px\] {
  right: -300px
}

.right-\[10px\] {
  right: 10px
}

.right-\[20px\] {
  right: 20px
}

.right-\[40px\] {
  right: 40px
}

.top-0 {
  top: 0px
}

.top-\[-250px\] {
  top: -250px
}

.top-\[-300px\] {
  top: -300px
}

.top-\[-80px\] {
  top: -80px
}

.top-\[-90px\] {
  top: -90px
}

.top-\[100px\] {
  top: 100px
}

.top-\[125px\] {
  top: 125px
}

.top-\[160px\] {
  top: 160px
}

.top-\[180px\] {
  top: 180px
}

.top-\[500px\] {
  top: 500px
}

.top-\[80px\] {
  top: 80px
}

.-z-10 {
  z-index: -10
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-\[-100\] {
  z-index: -100
}

.z-\[-10\] {
  z-index: -10
}

.z-\[-15\] {
  z-index: -15
}

.m-0 {
  margin: 0px
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem
}

.-mb-2 {
  margin-bottom: -0.5rem
}

.-mb-6 {
  margin-bottom: -1.5rem
}

.-mt-4 {
  margin-top: -1rem
}

.-mt-\[10rem\] {
  margin-top: -10rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mb-auto {
  margin-bottom: auto
}

.mr-6 {
  margin-right: 1.5rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mt-16 {
  margin-top: 4rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-20 {
  margin-top: 5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-8 {
  margin-top: 2rem
}

.-mt-\[15rem\] {
  margin-top: -15rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mt-24 {
  margin-top: 6rem
}

.mb-24 {
  margin-bottom: 6rem
}

.box-border {
  box-sizing: border-box
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.h-10 {
  height: 2.5rem
}

.h-5 {
  height: 1.25rem
}

.h-6 {
  height: 1.5rem
}

.h-8 {
  height: 2rem
}

.h-\[120px\] {
  height: 120px
}

.h-\[12rem\] {
  height: 12rem
}

.h-\[13\.5rem\] {
  height: 13.5rem
}

.h-\[24px\] {
  height: 24px
}

.h-\[30px\] {
  height: 30px
}

.h-\[3rem\] {
  height: 3rem
}

.h-\[42px\] {
  height: 42px
}

.h-\[450px\] {
  height: 450px
}

.h-\[49px\] {
  height: 49px
}

.h-\[5rem\] {
  height: 5rem
}

.h-\[775px\] {
  height: 775px
}

.h-\[850px\] {
  height: 850px
}

.h-full {
  height: 100%
}

.h-1 {
  height: 0.25rem
}

.h-12 {
  height: 3rem
}

.h-2 {
  height: 0.5rem
}

.w-10 {
  width: 2.5rem
}

.w-5 {
  width: 1.25rem
}

.w-6 {
  width: 1.5rem
}

.w-8 {
  width: 2rem
}

.w-\[100\%\] {
  width: 100%
}

.w-\[1000px\] {
  width: 1000px
}

.w-\[120px\] {
  width: 120px
}

.w-\[140px\] {
  width: 140px
}

.w-\[150px\] {
  width: 150px
}

.w-\[18rem\] {
  width: 18rem
}

.w-\[195px\] {
  width: 195px
}

.w-\[2000px\] {
  width: 2000px
}

.w-\[200px\] {
  width: 200px
}

.w-\[22rem\] {
  width: 22rem
}

.w-\[250px\] {
  width: 250px
}

.w-\[30px\] {
  width: 30px
}

.w-\[3rem\] {
  width: 3rem
}

.w-\[400px\] {
  width: 400px
}

.w-\[550px\] {
  width: 550px
}

.w-\[5rem\] {
  width: 5rem
}

.w-\[650px\] {
  width: 650px
}

.w-\[70px\] {
  width: 70px
}

.w-\[750px\] {
  width: 750px
}

.w-auto {
  width: auto
}

.w-full {
  width: 100%
}

.w-\[80\%\] {
  width: 80%
}

.w-12 {
  width: 3rem
}

.w-16 {
  width: 4rem
}

.w-24 {
  width: 6rem
}

.w-20 {
  width: 5rem
}

.w-\[30rem\] {
  width: 30rem
}

.w-\[40rem\] {
  width: 40rem
}

.max-w-\[1200px\] {
  max-width: 1200px
}

.max-w-\[15\.5rem\] {
  max-width: 15.5rem
}

.max-w-\[2000px\] {
  max-width: 2000px
}

.max-w-\[25rem\] {
  max-width: 25rem
}

.max-w-\[880px\] {
  max-width: 880px
}

.max-w-full {
  max-width: 100%
}

.max-w-\[30rem\] {
  max-width: 30rem
}

.max-w-\[50rem\] {
  max-width: 50rem
}

.max-w-\[60rem\] {
  max-width: 60rem
}

.max-w-\[70rem\] {
  max-width: 70rem
}

.max-w-\[75rem\] {
  max-width: 75rem
}

.shrink-0 {
  flex-shrink: 0
}

.grow {
  flex-grow: 1
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-\[0\.7\] {
  --tw-scale-x: 0.7;
  --tw-scale-y: 0.7;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-\[0\.8\] {
  --tw-scale-x: 0.8;
  --tw-scale-y: 0.8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.list-none {
  list-style-type: none
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-1 {
  gap: 0.25rem
}

.gap-10 {
  gap: 2.5rem
}

.gap-12 {
  gap: 3rem
}

.gap-20 {
  gap: 5rem
}

.gap-3 {
  gap: 0.75rem
}

.gap-4 {
  gap: 1rem
}

.gap-5 {
  gap: 1.25rem
}

.gap-6 {
  gap: 1.5rem
}

.gap-7 {
  gap: 1.75rem
}

.gap-8 {
  gap: 2rem
}

.overflow-hidden {
  overflow: hidden
}

.overflow-scroll {
  overflow: scroll
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.border {
  border-width: 1px
}

.border-\[1px\] {
  border-width: 1px
}

.border-\[2px\] {
  border-width: 2px
}

.border-0 {
  border-width: 0px
}

.border-b-\[8px\] {
  border-bottom-width: 8px
}

.border-b {
  border-bottom-width: 1px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-solid {
  border-style: solid
}

.border-none {
  border-style: none
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity))
}

.border-black\/40 {
  border-color: rgb(0 0 0 / 0.4)
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.border-\[\#E5E5E5\] {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity))
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-\[\#E5E5E5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.object-contain {
  object-fit: contain
}

.p-0 {
  padding: 0px
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem
}

.\!px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px
}

.pb-12 {
  padding-bottom: 3rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pb-24 {
  padding-bottom: 6rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pt-16 {
  padding-top: 4rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pt-8 {
  padding-top: 2rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pt-24 {
  padding-top: 6rem
}

.pt-32 {
  padding-top: 8rem
}

.pt-64 {
  padding-top: 16rem
}

.pt-48 {
  padding-top: 12rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.font-\[inherit\] {
  font-family: inherit
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.text-\[1\.5rem\] {
  font-size: 1.5rem
}

.text-\[13px\] {
  font-size: 13px
}

.text-\[14px\] {
  font-size: 14px
}

.text-\[16px\] {
  font-size: 16px
}

.text-\[30px\] {
  font-size: 30px
}

.text-\[32px\] {
  font-size: 32px
}

.text-\[36px\] {
  font-size: 36px
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.text-\[1\.45rem\] {
  font-size: 1.45rem
}

.text-\[1\.4rem\] {
  font-size: 1.4rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-\[24px\] {
  font-size: 24px
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-5xl {
  font-size: 3rem;
  line-height: 1
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1
}

.text-8xl {
  font-size: 6rem;
  line-height: 1
}

.text-\[2rem\] {
  font-size: 2rem
}

.font-\[500\] {
  font-weight: 500
}

.font-\[600\] {
  font-weight: 600
}

.font-\[700\] {
  font-weight: 700
}

.font-bold {
  font-weight: 700
}

.font-medium {
  font-weight: 500
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.font-\[800\] {
  font-weight: 800
}

.italic {
  font-style: italic
}

.leading-5 {
  line-height: 1.25rem
}

.leading-7 {
  line-height: 1.75rem
}

.leading-8 {
  line-height: 2rem
}

.text-\[\#003325\] {
  --tw-text-opacity: 1;
  color: rgb(0 51 37 / var(--tw-text-opacity))
}

.text-\[\#006548\] {
  --tw-text-opacity: 1;
  color: rgb(0 101 72 / var(--tw-text-opacity))
}

.text-black\/50 {
  color: rgb(0 0 0 / 0.5)
}

.text-black\/80 {
  color: rgb(0 0 0 / 0.8)
}

.text-black\/90 {
  color: rgb(0 0 0 / 0.9)
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-white\/80 {
  color: rgb(255 255 255 / 0.8)
}

.underline {
  text-decoration-line: underline
}

.opacity-70 {
  opacity: 0.7
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.contrast-200 {
  --tw-contrast: contrast(2);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.contrast-\[2\] {
  --tw-contrast: contrast(2);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.\[appearance\:textfield\] {
  -webkit-appearance: textfield;
          appearance: textfield
}

.hover\:opacity-100:hover {
  opacity: 1
}

.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

@media (min-width: 640px) {
  .sm\:flex {
    display: flex
  }

  .sm\:justify-between {
    justify-content: space-between
  }

  .sm\:text-\[48px\] {
    font-size: 48px
  }
}

@media (min-width: 768px) {
  .md\:bottom-\[-20px\] {
    bottom: -20px
  }

  .md\:bottom-\[-50px\] {
    bottom: -50px
  }

  .md\:bottom-\[100px\] {
    bottom: 100px
  }

  .md\:bottom-\[20px\] {
    bottom: 20px
  }

  .md\:bottom-\[25px\] {
    bottom: 25px
  }

  .md\:left-\[-20px\] {
    left: -20px
  }

  .md\:left-\[-60px\] {
    left: -60px
  }

  .md\:left-\[0px\] {
    left: 0px
  }

  .md\:right-\[-300px\] {
    right: -300px
  }

  .md\:right-\[-500px\] {
    right: -500px
  }

  .md\:right-\[75px\] {
    right: 75px
  }

  .md\:top-\[-180px\] {
    top: -180px
  }

  .md\:top-\[-70px\] {
    top: -70px
  }

  .md\:top-\[250px\] {
    top: 250px
  }

  .md\:top-\[300px\] {
    top: 300px
  }

  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px
  }

  .md\:mb-0 {
    margin-bottom: 0px
  }

  .md\:mr-0 {
    margin-right: 0px
  }

  .md\:mr-16 {
    margin-right: 4rem
  }

  .md\:mt-8 {
    margin-top: 2rem
  }

  .md\:block {
    display: block
  }

  .md\:h-\[38px\] {
    height: 38px
  }

  .md\:h-\[550px\] {
    height: 550px
  }

  .md\:w-\[120px\] {
    width: 120px
  }

  .md\:w-\[650px\] {
    width: 650px
  }

  .md\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:justify-start {
    justify-content: flex-start
  }

  .md\:gap-20 {
    gap: 5rem
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .md\:\!px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }

  .md\:pb-28 {
    padding-bottom: 7rem
  }

  .md\:pt-64 {
    padding-top: 16rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  .md\:text-\[42px\] {
    font-size: 42px
  }

  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  .md\:leading-8 {
    line-height: 2rem
  }

  .md\:leading-\[1rem\] {
    line-height: 1rem
  }

  .md\:leading-\[2rem\] {
    line-height: 2rem
  }

  .md\:leading-\[3rem\] {
    line-height: 3rem
  }

  .md\:leading-\[4rem\] {
    line-height: 4rem
  }
}

@media (min-width: 1024px) {
  .lg\:right-\[-260px\] {
    right: -260px
  }

  .lg\:right-\[-300px\] {
    right: -300px
  }

  .lg\:right-\[-50px\] {
    right: -50px
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }
}

.\[\&\:\:-webkit-inner-spin-button\]\:appearance-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none
}

.\[\&\:\:-webkit-outer-spin-button\]\:appearance-none::-webkit-outer-spin-button {
  -webkit-appearance: none;
          appearance: none
}
