/* Transition animation removed */
.Modal {
  width: 319px;
  /* height: 436px; */
  position: fixed;
  top: 50%;
  left: 50%;
  align-items: flex-end;
  padding: 67px 19px 44px;
  z-index: 5;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 20px 20px 44px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  color: var(--neutral);
  /* transition: all 0.3s ease-out; */
}

.Modal,
.signupForm {
  display: flex;
  flex-direction: column;
}

.Modal img {
  position: absolute;
  top: 19px;
  right: 19px;
}
.Modal img:hover {
  cursor: pointer;
}

.signupForm {
  padding: 0;
}

.signupForm input {
  width: 281px;
  height: 52px;
  margin-top: 24px;
  padding: 16px 5px 16px 8px;
  font-size: 14px;
  line-height: 145%;
  color: var(--neutral);
  border: none;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e1e1e1;
  transition: background 0.1s ease, box-shadow 0s ease;
}

.signupForm button {
  width: 281px;
  height: 52px;
  margin-top: 10px;
  justify-content: center;
  background: var(--secondary3);
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 100px;
  border: none;
  cursor: pointer;
}

.signupForm button,
.headerText {
  display: flex;
  align-items: center;
}

.signupForm input:focus {
  box-shadow: inset 0 0 0 1px #007fff, 0 0 0 1px #007fff;
}

.headerText {
  font-size: 18px;
  line-height: 145%;
  text-align: center;
}

.subText {
  margin-top: 24px;
  font-size: 12px;
  line-height: 145%;
}
