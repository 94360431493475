.componentContainer {
  max-width: 1600px;
  margin: 0px auto;
  padding: 84px 0 38px;
  color: var(--neutral);
}

.componentHeading,
.componentContainer {
  text-align: center;
}

.componentHeading > h2 {
  font-size: 42px;
  line-height: 46px;
}

.componentHeading > h2,
.componentHeading > h3 {
  font-family: "Gilroy", sans-serif;
  letter-spacing: -0.4px;
}
.componentHeading > h3 {
  max-width: 573px;
  margin: 11px auto 0;
  font-size: 22px;
  line-height: 24px;
}

.cardsContainer {
  max-width: 910px;
  margin: 75px auto 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1000px) {
  .componentContainer {
    padding: 74px 0 23px;
  }

  .componentHeading > h2 {
    max-width: 450px;
    margin: auto;
    padding: 0 4.5%;
  }

  .componentHeading > h3 {
    margin-top: 26px;
  }

  .cardsContainer {
    flex-direction: column;
  }

  .cardsContainer > a:nth-child(n + 2) {
    margin-top: 35px;
  }
}
